<template>
  <b-card
    no-body
    class="tour-details-card"
  >
    <div class="tour-header">
      <h4 class="text-primary">
        {{ tourData.tour ? tourData.tour.name : 'N/A' }}
        | {{ formatDate(tourData.availability[0].parent_availability.date) }}
      </h4>
      <small v-if="tourData.tour.location">
        {{ tourData.tour.location.name ? tourData.tour.location.name : '' }}
        {{ tourData.tour.location.country && tourData.tour.location.country.country_name
          ? `, ${tourData.tour.location.country.country_name}` : '' }}
      </small>
    </div>
    <div class="text-info-wrapper">
      <div class="person-count">
        <feather-icon
          icon="UsersIcon"
          size="14"
        />
        <span>
          {{ calculateTotalTravellers(tourData, tourData.availability) }}
        </span>
      </div>
      <div class="options">
        <h4>Options</h4>
        <span
          v-for="(availability, a) in tourData.availability"
          :key="a"
        >
          {{ availability.parent_availability ? availability.parent_availability.option.name
            : '' }}
          {{ availability.child_availability && availability.child_availability.option
            ? `- ${availability.child_availability.option.name}` : '' }} x
          {{ availability.quantity }}
        </span>
      </div>
      <div
        v-if="tourData.add_on_list && tourData.add_on_list.length"
        class="options addons"
      >
        <h5>Add-Ons</h5>
        <span
          v-for="addon in tourData.add_on_list"
          :key="`addon-${addon.id}`"
        >
          {{ addon.add_on.name }} x {{ addon.quantity }} <br>
        </span>
      </div>
      <div
        v-if="tourData.pickup_time"
        class="options pickup"
      >
        <h5>Pick Up</h5>
        <span>
          {{ tourData.pickup_time.name }}
        </span>
      </div>
    </div>
  </b-card>
</template>

<script>
import helper from '@/mixins/helper'

export default {
  name: 'TourCard',
  mixins: [helper],
  props: {
    tourData: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    calculateTotalTravellers(tour, availability) {
      let adult = 0
      let infant = 0
      let child = 0
      availability.forEach(a => {
        adult += a.adult
        child += a.child
        infant += a.infant
      })
      if (tour.adult_pax > adult) {
        adult = tour.adult_pax
      }
      if (tour.child_pax > child) {
        child = tour.child_pax
      }
      if (tour.infant_pax > infant) {
        infant = tour.infant_pax
      }
      // eslint-disable-next-line no-nested-ternary
      const adultText = adult ? adult > 1 ? `${adult} Adults` : `${adult} Adult` : ''
      // eslint-disable-next-line no-nested-ternary
      const childText = child ? child > 1 ? `,${child} Children` : `, ${child} Child` : ''
      // eslint-disable-next-line no-nested-ternary
      const infantText = infant ? infant > 1 ? `,${infant} Infants` : `, ${infant} Infant` : ''
      return `${adultText} ${childText} ${infantText}`
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import '~@core/scss/base/components/variables-dark';

.tour-details-card {
  padding: 0 10px;
  .tour-header {
    padding-bottom: 10px;
    border-bottom: 1px solid $table-border-color;
  }
  .text-info-wrapper {
    .person-count, .options {
      padding: 15px 0;
      border-bottom: 1px solid $table-border-color;
      &:is(:last-child) {
        border: none;
      }
    }
  }
}

.dark-layout {
  .tour-details-card {
    .tour-header,
    .text-info-wrapper .person-count,
    .text-info-wrapper .options {
      border-bottom: 1px solid $theme-dark-border-color;
    }
  }
}
</style>
