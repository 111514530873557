<template>
  <b-sidebar
    id="details-view"
    ref="sidebar"
    width="65%"
    title="Tour Details"
    bg-variant="white"
    right
    backdrop
    no-header
    @shown="prepareData()"
    @hidden="resetData()"
  >
    <template #default="{ hide }">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Booking Details - {{ tourData ? tourData.booking_id : '' }}
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <b-card
        no-body
        class="pb-1"
      >
        <b-tabs
          v-if="tourData && requestType === 'details'"
          pills
          class="mt-1"
        >
          <b-tab
            v-if="tourData && Object.keys(tourData).length !== 0"
            title-link-class="ml-1"
            active
          >
            <template #title>
              <feather-icon
                icon="FileTextIcon"
                size="16"
                class="mr-0 mr-sm-50"
              />
              <span class="d-none d-sm-inline">Tour</span>
            </template>
            <div class="pl-1">
              <tour-card :tour-data="tourData" />
            </div>
          </b-tab>
          <b-tab>
            <template #title>
              <feather-icon
                icon="UserIcon"
                size="16"
                class="mr-0 mr-sm-50"
              />
              <span class="d-none d-sm-inline">Contact</span>
            </template>
            <div
              v-if="tourData.primary_contact"
              class="pl-1 mt-1"
            >
              <b-row>
                <b-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <small class="text-muted">Name</small>
                  <p>{{ tourData.primary_contact.name }}</p>
                </b-col>
                <b-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <small class="text-muted">Email</small>
                  <p>{{ tourData.primary_contact.email }}</p>
                </b-col>
                <b-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <small class="text-muted">Phone</small>
                  <p>{{ tourData.primary_contact.phone }}</p>
                </b-col>
              </b-row>
            </div>
          </b-tab>
          <b-tab
            v-if="invoiceData && Object.keys(invoiceData).length !== 0"
          >
            <template #title>
              <feather-icon
                icon="FileTextIcon"
                size="16"
                class="mr-0 mr-sm-50"
              />
              <span class="d-none d-sm-inline">Invoice</span>
            </template>
            <div class="pl-1">
              <invoice
                :invoice="invoiceData"
                :cross-sale="tourData.cross_sell_discount"
                :invoice-type="invoiceData && invoiceData.invoice_id
                  && invoiceData.invoice_id.startsWith('BEI') ? 'corporate' : ''"
              />
            </div>
          </b-tab>
          <b-tab v-if="invoiceData && invoiceData.transactions && invoiceData.transactions.length">
            <template #title>
              <feather-icon
                icon="GitBranchIcon"
                size="16"
                class="mr-0 mr-sm-50"
              />
              <span class="d-none d-sm-inline">Transaction</span>
            </template>
          </b-tab>
        </b-tabs>
      </b-card>
    </template>
  </b-sidebar>
</template>

<script>
import Invoice from '@/components/common/Invoice.vue'
import TourCard from '@/components/tour/TourCard.vue'

export default {
  name: 'TourDetailsView',
  components: {
    Invoice, TourCard,
  },
  props: {
    tourData: {
      type: Object,
    },
    requestType: {
      type: String,
    },
  },
  data() {
    return {
      apiRequest: 'post',
      invoiceData: {},
      billMeta: {
        offset: 0,
        total: 0,
        limit: 10,
      },
    }
  },
  methods: {
    resetData() {
      this.apiRequest = 'post'
      this.invoiceData = {}
    },
    async prepareData() {
      this.invoiceData = this.tourData.invoice
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
